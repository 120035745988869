.selectTimeFrame {
    border: 1px solid #0d6efd;
    padding: 10px;
    border-radius: 10px;
    min-width: 100px;
    width: fit-content;
    cursor: pointer;
    vertical-align: middle !important;
    align-self: center !important;
}

.form-select {
    max-width: fit-content;
    display: inline;
    background: #212529;
    color: white;
}

.form-control {
    max-width: fit-content;
    background: #212529;
    color: white;
}

.page-link {
    background-color: #212529;
}

.react-datepicker__input-container input {
    background-color: #212529;
    color: white;
}

.float-control {
    position: fixed;
    z-index: 9;
    top: 0px;
}

form[name="TimeFrames"] {
    display: inline;
}
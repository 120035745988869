code {
    font-size: 20px;
    font-weight: bold;
}

.tag {
    padding: 0px 3px 0px 3px;
    border: calc(1px + 0.2vw) solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-size: cover;
    box-sizing: border-box;
    box-shadow: 0 0 2px 2px rgb(0 0 0 / 50%);
}
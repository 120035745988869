.button-choose-file {

    label {
        background-color: indigo;
        color: white;
        padding: 0.5rem;
        border-radius: 0.3rem;
        cursor: pointer;
    }

    .file-chosen {
        direction: rtl;
        width: 5em;
        margin-left: 0.3rem;
    }
}
.altcoins {
    color: aliceblue;
}

.AltcoinSeasonPoints {
    th {
        cursor: pointer;
    }

}

.altcoinPair {
    .title {
        font-size: 15px;

        button {
            font-size: 15px;
            padding: 0px 5px;
        }
    }
}
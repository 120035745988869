@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.themeMode {
    background-color: #212529;
    color: #f6f6f6;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}


body {
    background: #212529;
    color: aliceblue;

    // #router {
    //     min-height: 100vh;

    // }

    .footer {
        text-align: center;
        margin-top: 50px;

        .waves {
            position: absolute;
            // top: -100px;
            left: 0;
            width: 100%;
            height: 100px;
            background-size: 1000px 100px;

            .wave {
                position: absolute;
                left: 0;
                width: 100%;
                height: 100px;
                background: url(../public/images/wave.png);
                background-size: 1000px 100px;
            }

            .wave1 {
                z-index: 1000;
                opacity: 1;
                bottom: 0;
                animation: animateWaves 4s linear infinite;
            }

            .wave2 {
                z-index: 999;
                opacity: 0.5;
                bottom: 10px;
                animation: animate 4s linear infinite !important;
            }

            .wave3 {
                z-index: 1000;
                opacity: 0.2;
                bottom: 15px;
                animation: animateWaves 3s linear infinite;
            }

            .wave4 {
                z-index: 999;
                opacity: 0.7;
                bottom: 20px;
                animation: animate 3s linear infinite;
            }
        }

        .social-links {
            .social-link {
                margin: 5px;

                img {
                    width: 24px;
                    height: 24px;
                }

                border-radius: 50%;
                padding: 5px;
                background-color: #f6f6f6;

                :hover {
                    border-style: 1px;
                    border-radius: 50%;
                    padding: 5px;
                    background-color: #83a003;
                }
            }
        }

        p {
            text-align: center;
        }
    }
}

#basic-navbar-nav {
    a.nav-link {
        color: #f6f6f6;
        font-size: large;
        border-style: solid;
        border-color: rgb(126, 114, 114);
        border-width: 0px 0px 0px 1px;
    }
}

.btn-switch-theme {
    cursor: pointer;
}


.btn-close {
    background-color: #212529;
    color: #f6f6f6;
}

@keyframes animateWaves {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}

@keyframes animate {
    0% {
        background-position-x: -1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}
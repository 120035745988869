.AltcoinSeasion {
    color: #fff;
    font-size: 30px;

    .AltCoinSeasionDay {
        b {
            color: red;
        }

    }

    .AltCoinSeasionHour {
        b {
            color: red;
        }
    }

}

.time-remaining {
    min-width: 5em;
}